import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: to => {
                return window.sessionStorage.getItem("menuFirst")
            },
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
            redirect: to => {
                return window.sessionStorage.getItem("menuFirst")
            },
            meta: { title: '自述文件' },
            children: [
                /**
                 * 监测信息
                 */
                {
                    path: '/gis',
                    component: () => import(/* webpackChunkName: "gis" */ '../components/page/monitoringInfo/gis.vue'),
                    name: '/gis',
                    meta: { title: 'gis管理' }
                },
                {
                    path: '/gisPress',
                    component: () => import(/* webpackChunkName: "gis" */ '../components/page/monitoringInfo/pressure.vue'),
                    name: '/gisPress',
                    meta: { title: '热力分析' }
                },

                {
                    path: '/map',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/monitoringInfo/Map.vue'),
                    meta: { title: '地理信息' }
                },
                {
                    path: '/historical/data',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/monitoringInfo/HistoricalData.vue'),
                    name: '/historical/data',
                    meta: { title: '在线数据' }
                },
                {
                    path: '/real/time/data',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/monitoringInfo/RealTimeData.vue'),
                    name: '/real/time/data',
                    meta: { title: '历史数据' }
                },
                {
                    path: '/original/data',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/monitoringInfo/OriginalData.vue'),
                    name: '/original/data',
                    meta: { title: '多表分析' }
                },
                {
                    path: '/sensor',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/monitoringInfo/sensor.vue'),
                    name: '/sensor',
                    meta: { title: '压力传感器' }
                },
                {
                    path: '/newReport',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/monitoringInfo/newReport.vue'),
                    name: '/newReport',
                    meta: { title: '最新上报' }
                },

                /**
                 * 数据分析
                 */
                {
                    path: '/dashboard',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
                    meta: { title: '数据分析' }
                },

                /**
                 * 设备管理
                 */
                {
                    path: '/valveControl',
                    component: () => import(/* webpackChunkName: "valveControl" */ '../components/page/meter/valveControl.vue'),
                    meta: { title: '阀控' }
                },

                /**
                 * 用量统计
                 */
                {
                    path: '/ystj',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/WaterReport.vue'),
                    meta: { title: '用水统计' }
                },
                {
                    path: '/report/d',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/ReportD.vue'),
                    meta: { title: '日报表' }
                },
                {
                    path: '/report/m',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/ReportM.vue'),
                    meta: { title: '月报表' }
                },
                {
                    path: '/report/y',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/ReportY.vue'),
                    meta: { title: '年报表' }
                },
                {
                    path: '/report/original',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/ReportOriginal.vue'),
                    meta: { title: '历史数据' }
                },
                {
                    path: '/businessStatement',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/waterReport/BusinessStatement.vue'),
                    meta: { title: '营业数据报表' }
                },

                /**
                 * 事件报警
                 */
                {
                    path: '/event/alarm',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/eventAlarm/EventAlarm.vue'),
                    meta: { title: '报警查询' }
                },
                {
                    path: '/alarm/rule',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/eventAlarm/AlarmRule.vue'),
                    meta: { title: '报警规则' }
                },
                {
                    path: '/alarm/contacts',
                    component: () => import(/* webpackChunkName: "monitoring" */ '../components/page/eventAlarm/AlarmContacts.vue'),
                    meta: { title: '报警联系人' }
                },
                /**
                 * 设备管理
                 */
                {
                    path: '/meter',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/meter/Meter.vue'),
                    meta: { title: '设备管理' }
                },
                {
                    path: '/gateway',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/meter/Gateway.vue'),
                    meta: { title: '网管设备管理' }
                },
                {
                    path: '/valveControl',
                    component: () => import(/* webpackChunkName: "valveControl" */ '../components/page/meter/valveControl.vue'),
                    meta: { title: '阀控' }
                },
                /**
                 * 压力中心
                 */
                {
                    path: '/pressureMap',
                    component: () => import(/* webpackChunkName: "pressure" */ '../components/page/pressure/pressureMap.vue'),
                    meta: { title: '地图监测' }
                },
                {
                    path: '/sensorPanel',
                    component: () => import(/* webpackChunkName: "pressure" */ '../components/page/pressure/panel.vue'),
                    meta: { title: '压力面板' }
                },
                {
                    path: '/runreport',
                    component: () => import(/* webpackChunkName: "pressure" */ '../components/page/pressure/runReport.vue'),
                    meta: { title: '运行报表' }
                },
                /**
                 * 系统管理
                 */
                {
                    path: '/menu',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/system/Menu.vue'),
                    meta: { title: '菜单管理' }
                },
                {
                    path: '/fun/point',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/system/FunPoint.vue'),
                    meta: { title: '接口管理' }
                },
                {
                    path: '/role',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/system/Role.vue'),
                    meta: { title: '角色管理' }
                },
                {
                    path: '/operate/log',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/system/OperateLog.vue'),
                    meta: { title: '操作日志' }
                },
                {
                    path: '/company',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/meter/Company.vue'),
                    meta: { title: '组织管理' }
                },
                {
                    path: '/oss/user',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/system/OssUser.vue'),
                    meta: { title: '用户管理' }
                },

                /**
                 * 资产管理
                 */
                {
                    path: '/assets/list',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/assets/DeviceList.vue'),
                    meta: { title: '资产列表' }
                },
                {
                    path: '/assets/info',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/assets/DeviceTyps.vue'),
                    meta: { title: '公共信息配置' }
                },
                {
                    path: '/assets/type',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/assets/AsstesType.vue'),
                    meta: { title: '资产类型配置' }
                },
                {
                    path: '/pipeMap',
                    component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/assets/component/pipeMap.vue'),
                    meta: { title: '添加管道' }
                },
                /**
                 * 其他
                 */
                {
                    path: '/404',
                    component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
                    meta: { title: '404' }
                },
                {
                    path: '/refuse',
                    component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
                    meta: { title: '403' }
                },
            ]
        },
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '/wx/user/bind',
            component: () => import(/* webpackChunkName: "403" */ '../components/page/WXUserBind.vue'),
            meta: { title: '微信用户绑定结果页面' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});
