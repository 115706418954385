import Vue from 'vue';
import ELEMENT,{ Table } from 'element-ui';
import 'element-theme-chalk';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import axios from 'axios'
//高德调取
import AmapVue from '@amap/amap-vue';
import 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import XEUtils from 'xe-utils'
import 'vxe-table/lib/style.css'
import '@/assets/css/icon.css'
import App from './App.vue';
import router from './router';
// Vue.config.productionTip = false;
// const fixElTableErr = table => {
//   const oldResizeListener = table.methods.resizeListener
//   table.methods.resizeListener = function () {
//     window.requestAnimationFrame(oldResizeListener.bind(this))
//   }
// }
// fixElTableErr(Table)
Vue.use(ELEMENT);
Vue.use(VXETable);
Vue.prototype.$XModal = VXETable.modal;
console.log('当前NODE_ENV：' + process.env.NODE_ENV)
console.log('当前VUE_APP_CURRENTMODE：' + process.env.VUE_APP_CURRENTMODE)
//高德使用
AmapVue.config.version = '1.4.15'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'deb7298c2385e41d9b8a957ff085bdfd';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  'AMap.AutoComplete', 
  'AMap.PlaceSearch'

  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);

VXETable.setup({
  keepSource: true,
});
VXETable.use(VXETablePluginExportXLSX)
// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  //状态值装欢
  formatState({cellValue}){
    console.log(cellValue == '0')
    if(cellValue == '0'){
      return '使用中'
    }else{
      return '禁止'
    }
  },
  // 格式化性别
  formatSex({ cellValue }) {
    return cellValue ? (cellValue === '1' ? '男' : '女') : ''
  },
  // 格式化下拉选项
  formatSelect({ cellValue }, list) {
    const item = list.find(item => item.value === cellValue)
    return item ? item.label : ''
  },
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  formatDate({ cellValue }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
  },
  // 四舍五入,默认两位数
  formatFixedNumber({ cellValue }, digits = 2) {
    return XEUtils.toFixed(XEUtils.round(cellValue, digits), digits)
  },
  // 向下舍入,默认两位数
  formatCutNumber({ cellValue }, digits = 2) {
    if (cellValue||cellValue==0) {
      return XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits)
    } else {
      return '--.--'
    }

  },
  // 转换 moment 类型为字符串
  toMomentString({ cellValue }, format) {
    return cellValue ? cellValue.format(format) : ''
  }
})
Vue.prototype.$http = axios;
// 全局时间格式过滤器
Vue.filter('dateFormat', function (originVal) {
  if(originVal == null || originVal == ''){ return '--'}
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0') // 补零
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

//全局用水过滤器
Vue.filter('dateFNum', function (f) {
  if (isNaN(f)) {
    return '--.--';
  }
  f = Math.floor(f * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;

})


// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  if (to.path === '/wx/user/bind') return next()
  // 获取token
  const token = window.sessionStorage.getItem('token')
  if (token === null || token === '') return next('/login')
  next()

});

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
