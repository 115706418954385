import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		// BASE_URL: 'http://127.0.0.1:8080',
		event_current: 1, // 工单页默认打开列表号,
		update: false, // 是否有更新版本
		chart1: Object,
		chart2: Object,
		color: window.localStorage.getItem("color") == '' ? 1 : window.localStorage.getItem("color")
		// 菜单集合
		// menuList: JSON.parse(localStorage.getItem('menuList'))
	},
	mutations: {
		SET_COLOR(state, color) {
			state.color = color
		},
		SET_UPDATE(state) {
			state.update = true
		},
		getChart1(state, id) {
			state.chart1 = id
		},
		setChart1(state, chart1) {
			state.chart1 = chart1
		},
		setChart2(state, chart1) {
			state.chart1 = chart1
		},
		setMenuList(state, menuList) {
			state.menuList = JSON.parse(menuList)
		}

	},
	actions: {
		update(content) {
			return new Promise((resolve) => {
				setTimeout(() => {
					content.commit('SET_UPDATE')
					resolve()
				}, 1000)
			})
		},
		setMenuList() {

		}
	},
	modules: {}
})



export default store
